"use client";
import React, {  useRef, useState } from "react";
import { motion } from "framer-motion";

import { SectionWrapper } from "../hoc";
import { slideIn } from "../utils/motion";
import dynamic from "next/dynamic";
const EarthCanvas = dynamic(() => import("./canvas/Earth"), { ssr: false });

const Contact = () => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
    service: "",
    phoneNumber: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e:  any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch("/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      });

      if (response.ok) {
        const data = await response.json();
        setForm({
          name: "",
          email: "",
          message: "",
          service: "",
          phoneNumber: ""
        });
        alert(data.message);
      } else {
        const data = await response.json();
        alert(data.message);
      }
    } catch (error) {
      alert("An error occurred while sending the email");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`xl:mt- flex xl:flex-row flex-col-reverse gap-10 overflow-hidden`}
    >
      <motion.div
        variants={slideIn("left", "tween", 0.2, 1)}
        className="flex-[0.75] bg-black-100 p-3 md:p-8 rounded-2xl"
      >
        <p className={`sectionSubText`}>Get in touch</p>
        <h3 className={`sectionHeadText`}>Contact.</h3>

        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="mt-12 flex flex-col gap-8"
        >
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Your Name</span>
            <input
              type="text"
              name="name"
              value={form.name}
              required
              maxLength={40}
              onChange={handleChange}
              placeholder="What's your good name?"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
            />
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Your email</span>
            <input
              type="email"
              name="email"
              value={form.email}
              maxLength={100}
              required
              onChange={handleChange}
              placeholder="What's your email address?"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
            />
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Your Number</span>
            <input
              type="number"
              name="phoneNumber"
              value={form.phoneNumber}
              onChange={handleChange}
              required
              maxLength={20}
              placeholder="What's your Phone number?"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
            />
          </label>
          <label className="flex flex-col w-full max-w-lg mb-8">
            <span className="text-white font-medium mb-4">
              Select a Service
            </span>
            <select
              name="service"
              value={form.service}
              onChange={handleChange}
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              required
            >
              <option value="" disabled >
                Select a service...
              </option>
              <option value="website-development">Website Development</option>
              <option value="app-development">App Development</option>
              <option value="ai-ml">AI Solutions</option>
              <option value="domain-hosting">Domain & Hosting</option>
              <option value="video-editing">Video Editing</option>
              <option value="graphics-design">Graphics Design</option>
              <option value="logo-design">Logo Design</option>
              <option value="Consulting-Strategy">Consulting & Strategy</option>
            </select>
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Your Message</span>
            <textarea
              rows={7}
              name="message"
              value={form.message}
              required
              onChange={handleChange}
              maxLength={1000}
              placeholder="What you want to say?"
              className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
            />
          </label>

          <button
            type="submit"
            className="bg-tertiary py-3 px-8 rounded-xl outline-none w-fit text-white font-bold shadow-md shadow-primary"
          >
            {loading ? "Sending..." : "Send"}
          </button>
        </form>
      </motion.div>

      <motion.div
        variants={slideIn("right", "tween", 0.2, 1)}
        className="xl:flex-1 xl:h-screen md:h-[550px] h-[350px]"
      >
        <EarthCanvas />
      </motion.div>
    </div>
  );
};

export default SectionWrapper(Contact, "");
